/**
 * Main Collection Slider
 * 
 * Module for handling the main collection slider functionality
 */

// Store Swiper instances for cleanup
const swiperInstances = {};

// Export the init function to be called from main JS
export function initMainCollectionSlider() {
  const sliderContainers = document.querySelectorAll('.main-collection-slider');
  
  if (!sliderContainers.length) return;
  
  // Check if it's mobile view
  const isMobile = window.innerWidth < 768;
  
  sliderContainers.forEach(container => {
    const sectionId = container.closest('[data-section-id]')?.getAttribute('data-section-id') || 'main-slider';
    
    // Clean up existing Swiper instance if it exists
    if (swiperInstances[sectionId]) {
      swiperInstances[sectionId].destroy(true, true);
      delete swiperInstances[sectionId];
    }
    
    // Get per-view settings from data attributes
    const desktopPerView = parseInt(container.getAttribute('data-collections-per-view')) || 4;
    const mobilePerView = parseInt(container.getAttribute('data-mobile-collections-per-view')) || 1;
    
    const swiperEl = container.querySelector('.swiper');
    if (!swiperEl) return;
    
    // Get navigation elements
    const prevButton = container.querySelector('.carousel-navigation__arrow--prev');
    const nextButton = container.querySelector('.carousel-navigation__arrow--next');
    
    // Apply consistent dimensions to slides and hide overlays
    const slides = Array.from(swiperEl.querySelectorAll('.swiper-slide'));
    if (!slides.length) return;
    
    // Force flex display on the swiper wrapper
    const swiperWrapper = swiperEl.querySelector('.swiper-wrapper');
    if (swiperWrapper) {
      swiperWrapper.style.display = 'flex';
      swiperWrapper.style.flexDirection = 'row';
      swiperWrapper.style.flexWrap = 'nowrap';
    }
    
    // Reset all slide styles before applying new ones
    slides.forEach(slide => {
      // Reset styles that might persist between mobile/desktop transitions
      slide.style.width = '';
      slide.style.scrollSnapAlign = '';
      slide.style.flexShrink = '0';
      
      // Hide any overlays
      const overlay = slide.querySelector('.main-collection-slider__collection-overlay');
      if (overlay) {
        overlay.style.display = 'none';
      }
      
      // Set aspect ratio for media container
      const media = slide.querySelector('.main-collection-slider__collection-media');
      if (media) {
        media.style.height = '0';
        media.style.paddingBottom = '100%';
      }
    });
    
    // For mobile, use native scrolling
    if (isMobile) {
      // Apply mobile-specific styles
      slides.forEach(slide => {
        slide.style.width = '85%';
        slide.style.scrollSnapAlign = 'start';
      });
      
      // Enable native scrolling
      if (swiperEl) {
        swiperEl.style.overflowX = 'auto';
        swiperEl.style.webkitOverflowScrolling = 'touch';
      }
      
      if (swiperWrapper) {
        swiperWrapper.style.scrollSnapType = 'x mandatory';
      }
      
      // Hide navigation
      if (prevButton) prevButton.style.display = 'none';
      if (nextButton) nextButton.style.display = 'none';
      
      // Hide scrollbar element for mobile
      const scrollbar = container.querySelector('.swiper-scrollbar');
      if (scrollbar) {
        scrollbar.style.display = 'none';
      }
      
      return; // Don't initialize Swiper on mobile
    }
    
    // Desktop styles
    if (swiperEl) {
      swiperEl.style.overflowX = '';
      swiperEl.style.webkitOverflowScrolling = '';
    }
    
    if (swiperWrapper) {
      swiperWrapper.style.scrollSnapType = '';
    }
    
    // Apply desktop-specific widths based on per-view setting
    const columnSetting = container.classList.contains('main-collection-slider--per-view-3') ? 3 : 
                          container.classList.contains('main-collection-slider--per-view-5') ? 5 : 4;
    
    slides.forEach(slide => {
      slide.style.scrollSnapAlign = '';
      
      if (columnSetting === 3) {
        slide.style.width = 'calc(33.33% - 12px)';
      } else if (columnSetting === 5) {
        slide.style.width = 'calc(20% - 12px)';
      } else {
        slide.style.width = 'calc(25% - 12px)';
      }
    });
    
    // Only initialize Swiper if we have more slides than visible
    if (slides.length <= desktopPerView) {
      // Not enough slides to warrant a slider on desktop
      if (prevButton) prevButton.style.display = 'none';
      if (nextButton) nextButton.style.display = 'none';
      return;
    }
    
    // Show but hide navigation visually (for screen readers)
    if (prevButton) {
      prevButton.style.display = '';
      prevButton.style.visibility = 'hidden';
    }
    if (nextButton) {
      nextButton.style.display = '';
      nextButton.style.visibility = 'hidden';
    }
    
    // Initialize Swiper for desktop
    try {
      const swiper = new Swiper(swiperEl, {
        direction: 'horizontal',
        slidesPerView: desktopPerView,
        spaceBetween: 16,
        grabCursor: true,
        watchOverflow: true,
        preventClicksPropagation: false,
        mousewheel: {
          forceToAxis: true
        },
        slidesPerGroup: 1,
        speed: 400,
        allowTouchMove: true,
        touchRatio: 1,
        navigation: {
          prevEl: prevButton,
          nextEl: nextButton,
        },
        scrollbar: {
          el: '.js-swiper-scrollbar-main-collection-slider',
          draggable: true,
        },
        on: {
          init: function() {
            setTimeout(() => {
              this.update();
            }, 100);
          }
        }
      });
      
      // Store swiper instance for cleanup
      swiperInstances[sectionId] = swiper;
      
    } catch (error) {
      console.error('Error initializing Swiper:', error);
    }
  });
}

// Add event listeners
document.addEventListener('DOMContentLoaded', initMainCollectionSlider);

// Reinitialize on Shopify section events
document.addEventListener('shopify:section:load', function(event) {
  if (event.target.querySelector('.main-collection-slider')) {
    setTimeout(initMainCollectionSlider, 100);
  }
});

// Reinitialize on Shopify section select events
document.addEventListener('shopify:section:select', function(event) {
  if (event.target.querySelector('.main-collection-slider')) {
    setTimeout(initMainCollectionSlider, 100);
  }
});

// Handle resize properly
let resizeTimer;
let lastWidth = window.innerWidth;

window.addEventListener('resize', function() {
  clearTimeout(resizeTimer);
  
  // Only reinitialize if width changes (not on height changes)
  if (lastWidth !== window.innerWidth) {
    // Check if we're crossing the mobile/desktop threshold
    const wasMobile = lastWidth < 768;
    const isMobile = window.innerWidth < 768;
    
    lastWidth = window.innerWidth;
    
    // If transitioning between mobile and desktop, reinitialize immediately
    if (wasMobile !== isMobile) {
      initMainCollectionSlider();
    } else {
      // Otherwise, debounce the resize
      resizeTimer = setTimeout(initMainCollectionSlider, 200);
    }
  }
});